import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
// ---------------------------------------------
import Header from "../components/header";
import Layout from "../components/layout";
import LightboxLink from "../components/lightbox/link";
import Picture from "../components/picture";
import Splash from "../components/splash";
import Transition, { Link } from "../components/transition";
import Revealer from "../components/revealer";
import Vimeo from "../components/vimeo";
import SEO from "../components/seo";
// Helpers
import resolveProjectUrl from "../helpers/resolveProjectUrl";

const Intro = () => (
  <Splash>
    <h1>Production</h1>
  </Splash>
)

const ProductionLink = ({ item, handleHighlight, artists, handleLink }) => {

  const [highlight, setHighlight] = handleHighlight;
  const project = item.project[0];
  // const artist = project.artist[0];
  const cover = project.thumbnails[0];
  const focusClassName = !highlight ? '' : project.slug === highlight.slug ? 'in-focus' : 'not-in-focus';
  const artist = artists.find(f => f.authorId === project.authorId)
	const to = resolveProjectUrl(project, artist)
	const coverSrc = cover && cover.source && cover.source[0];
	const isPortrait = (coverSrc && (coverSrc.height / coverSrc.width > 1)) || (cover && cover.videoWidth) && (cover.videoHeight / cover.videoWidth > 1);

  return (
    <div key={project.slug} className={`collage-item ${isPortrait ? 'portrait' : 'landscape'} ${focusClassName}`}>
      <Revealer>
        {(show) => (
          <>
            <Link to={to} handleLink={handleLink}>
              <div
                className={`featured-project__asset ${focusClassName}`}
                onMouseEnter={e => setHighlight(project)}
                onMouseLeave={e => setHighlight(false)}
              >
                {cover ? cover.vimeoUrl ? (
                  <Vimeo {...cover} reveal={{ isVisible: show }} />
                ) : (
                  <div className="image-thumb is-loaded">
                    <Picture {...cover.source[0]} resize={{ width: 640 }} />
                  </div>
                ) : (
                  <Vimeo reveal={{ isVisible: show }} />
                )}
              </div>
            </Link>
            <div className="project__info">
              <div className="project__name">
                <span className="title uppercase">{artist ? artist.title : project.projectTitle}</span>
                <i>for </i>
                <span className={true ? "" : "uppercase"}>{project.client}</span>
                <span className="heading--4 details">{item.detailsInline}</span>
              </div>
            </div>
          </>
        )}
      </Revealer>
    </div>
  )
}

const ProductionPage = ({ data, location }) => {

  const { longText, caseStudies } = data.craft.production[0];
  const artists = data.craft.artists;
  const [highlight, setHighlight] = useState(false);

  return (
    <Layout>
      <SEO title="Production" />
      <Transition
        location={location}
        intro={Intro}
        render={handleLink => (
          <>
            <LightboxLink handleLink={handleLink} />
            <Header handleLink={handleLink} />
            <main className="head-offset">
              <div className="info">
                <ReactMarkdown
                  source={longText}
                  escapeHtml={false}
                  className="copy-row centred"
                />
              </div>
              <div className="collage">
                {caseStudies.map((item, index) => {
                  return (
                    item.project[0] && (
                      <ProductionLink item={item} handleHighlight={[highlight, setHighlight]} artists={artists} handleLink={handleLink} key={`production-${index}`} />
                    )
                  )
                })}
              </div>
            </main>
          </>
        )}
      />
    </Layout>
  )
}

ProductionPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProductionPage

export const pageQuery = graphql`
  query ProductionPageQuery {
    craft {
      artists: entries(type: "Artists") {
        ... on Craft_artists_artists_Entry {
          id
          title
          slug
          authorId
        }
      }
      production: entries(type: "Production") {
        ... on Craft_production_production_Entry {
          id
          longText
          caseStudies {
            ... on Craft_caseStudies_caseStudy_BlockType {
              details
              detailsInline
              project {
                ... on Craft_projects_projects_Entry {
                  ...ProjectGallery
                }
              }
            }
          }
        }
      }
    }
  }
`
